.menu-project-element-about {
  position: relative;
  font-family: 'NeueMontreal-Medium', sans-serif;
  text-decoration: none;
  z-index: 10;
  min-height: 40px;
  min-width: 470px;
  height: 50px;
  z-index: 1000;
}

.menu-project-element-about:hover {
  color: #fb5803;
  transition: transform 0.3s ease;
}

.blue-about {
  color: #333;
}

.dark-about {
  color: #868686;
}

.about-mobile-links {
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  margin-bottom: 1rem;
  margin-left: 1rem;

  // border:#fb5803 1px solid;
}

// .about-mobile-links:first-child {
//     margin-left: 0;
// }

.link-individual-mobile {
  margin-left: 6px;
  // border: #fb5803 1px solid;
}

.link-individual-mobile:first-child {
  margin-left: 0;
}

@media screen and (max-height: 460px) {
  .about-mobile-links {
    display: none;
  }
}