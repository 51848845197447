.project-global-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;

    height: 40px;
    width: 100%;
    margin-bottom: -50px;

    /* border: #fb5803 10px solid; */
}

.project-secondary-container {
    position: fixed;
    bottom: 0;
    left: 0;
    margin-left: 35px;
    margin-bottom: 30px;

    max-width: 450px;

    text-align: left;
    font-size: 35px;

}

.project-menu-offsettop {}

.show {
    display: block;
}

.hide {
    display: none;
}