.lowerright-mobile-main-container {
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right:    1rem;
    margin-bottom:     1rem;
}

.lowerright-mobile-link-container {
    text-decoration: none;
    text-align: right;
    margin: 0px;
    font-size: 2.5rem;
    font-family: 'NeueMontreal-Medium', sans-serif;
}

.lowerright-mobile-link-container:hover {
    color: #fb5803;
}

@media screen and (max-width: 200px) {
    .lowerright-mobile-link-container {
        display: none;
    }
}

@media screen and (max-height: 150px) {
    .lowerright-mobile-link-container {
        display: none;
    }
}