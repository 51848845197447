@font-face {
  font-family: 'NeueMontreal-Bold';
  src: url('/public/fonts/NeueMontreal/PPNeueMontreal-Bold.otf') format('opentype');
}

body {
  margin: 0;
}

.global-container {
  position: relative;
  z-index: 0;

  /* border: #000000 1px solid; */
}

.centered-image {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100vw;
  max-width: 100vw;
  max-height: 100vh;
  min-height: 100vh;
  overflow: hidden;

  z-index: 0;

  /* border: #fb5803 1px solid; */
}

.centered-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.z-index-max-name-home {
  z-index: 100;
}

.name-home-dif {
  mix-blend-mode: difference;
  color: white;
}

.lower-right-diff {
  color: white;
  mix-blend-mode: difference;
}

.z-index-max-home {
  z-index: 0;
}


@media screen and (max-width: 769px) {
  .desktop-container {
    display: none;
  }
}

/* MOBILE */

.mobile-container {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  display: block;
  height: 100svh;
  width: 100vw;
  overflow-y: hidden;

  /* border: red 1px solid; */
}

.mobile-section {
  display: flex;
}

@media screen and (min-width: 768px) {
  .mobile-container {
    display: none;
  }

  .mobile-section {
    display: none;
  }
}

/* IPAD MINI */
/* @media only screen and (min-device-width: 768px)
and (max-device-width: 1024px)
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 1) {

  .desktop-container {
    display: none;
  }

  .mobile-container {
    display: block;
  }
} */

/* KINDLE FIRE HDX */
/* @media only screen
and (min-device-width: 800px)
and (max-device-width: 1280px)
and (orientation: landscape) {

  .desktop-container {
    display: none;
  }

  .mobile-container {
    display: block;
  }
} */

/* @media only screen
and (min-device-width: 800px)
and (max-device-width: 1280px) {

  .desktop-container {
    display: none;
  }

  .mobile-container {
    display: block;
  }
} */