.project-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 0px;
  margin: 0;
  border-radius: 0px;
  overflow: hidden;

  // border: red 1px solid;
}

.project-card img {
  transition: transform 0.3s ease-in-out;
  /* adjust 0.3s to your desired duration */
  will-change: transform;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
  -webkit-font-smoothing: subpixel-antialiased;
}

.works-project-title {
  text-align: left;
  margin-top: 5px;
  margin-bottom: -7px;
  padding: 5px 5px;
  font-family: 'NeueMontreal-Bold', sans-serif;
  font-size: 24px;
  // border: 1px solid #deb761;
}

.works-project-description {
  margin-top: 0px;
  padding: 5px 5px;
  text-align: left;
  font-family: 'NeueMontreal-Medium', sans-serif;
  font-size: 15px;
  will-change: transform;
  transform: translateZ(0);
  -webkit-font-smoothing: subpixel-antialiased;
}

.image-works-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  position: relative;
  // min-height: 300px;
  // min-width: 300px;
  margin: 0px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  // border: 1px solid #26d62f;
  cursor: url('/../public/plusO32.png') 16 16, auto;
}

.image-works {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  margin: auto;
  transition: transform 0.5s;
  will-change: transform;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
  // -webkit-font-smoothing: subpixel-antialiased;
}

.image-works:hover {
  transform: scale(1.05);
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: url('/../public/plusO32.png') 16 16, auto;
  text-decoration: none;
}

.tag {
  padding: 5px 10px;
  background-color: rgba(74, 74, 74, 0.6);
  /* tag background */
  border-radius: 15px;
  font-family: 'NeueMontreal-Medium', sans-serif;
  color: #ffffff;
  font-size: 12px;
  will-change: transform;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
  -webkit-font-smoothing: subpixel-antialiased;
}
















// -------------------------------------------
// Metallic shine

@keyframes shine {
  0% {
    transform: translateX(-100%) translateY(-100%) rotate(30deg);
  }

  80% {
    transform: translateX(-100%) translateY(-100%) rotate(30deg);
  }

  100% {
    transform: translateX(100%) translateY(100%) rotate(30deg);
  }
}

.wrapper {
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
}

.box--gradient {
  display: flex;
  height: 300px;
  margin: 0 10px;
  position: relative;
  width: 300px;
  z-index: 0;
  overflow: hidden;

  p {
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    flex-grow: 1;
    margin: 0;
    font-size: 30px;
  }

  &:after {
    content: '';
    top: 0;
    transform: translateX(100%) rotate(30deg);
    width: 300%;
    height: 300%;
    position: absolute;
    z-index: 1;
    animation: shine 3s infinite ease-in;
    background:
      linear-gradient(to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 50%,
        rgba(128, 186, 232, 0) 99%,
        rgba(128, 186, 232, 0) 100%);
  }

  &.silver {
    background: linear-gradient(to bottom, #bcc6cc, #eee, #bcc6cc);
  }

  &.gold {
    background: linear-gradient(to bottom, #c39738, #deb761, #c39738);

    &:after {
      animation-delay: 1s;
    }
  }

  &.platinum {
    background: linear-gradient(to bottom, #d5d7e5, #eee, #d5d7e5);

    &:after {
      animation-delay: 2s;
    }
  }
}