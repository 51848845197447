.workslist-main-container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100lvh;

    // overflow-y: auto;
}

.workslist-main-div-mobile {

    flex-grow: 1;
    margin-top: 0;
    margin-bottom: auto;
    z-index: auto;
}

.div-map-mobile-cover-picture {
    position: relative;

    margin: 0 !important;
    z-index: 0;
    height: auto;

}

.workslist-cover-mobile {

    // important trick to make the image without margin
    vertical-align: top;
    width: 100%;
    height: auto;
    margin: 0;

}

.workslist-cover-title {
    text-decoration: none;
    font: 1.2rem 'NeueMontreal-Medium', sans-serif;
    position: absolute;

    right: 0;
    bottom: 0;
    margin-bottom:  0.5rem;
    margin-right:    1rem;

    color: white;
    mix-blend-mode: difference;
}