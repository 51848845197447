.centered-image {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width:  100vw;
    max-width:  100vw;
    min-height: 100vh;
    height:     100vh;
    max-height: 100vh;

    overflow: hidden;

    filter: blur(0);
    transition: filter 0.5s ease-in-out;
    z-index: 0;
    object-fit: cover;
}

.ease-out-blur {
    filter: blur(3px);
}

.image-no-blur-project {
    min-width:  105%;
    max-width:  105%;
    min-height: 105%;
    height:     105%;
    max-height: 105%;
    margin: 0px;

    filter: blur(0);
    transition: filter 0.5s ease-in-out;

    margin: -10px -10px -10px -10px;
}

.image-blur-project {
    min-width:  105%;
    max-width:  105%;
    min-height: 105%;
    height:     105%;
    max-height: 105%;
    margin: 0px;

    filter: blur(3px);

    margin: -10px -10px -10px -10px;
}