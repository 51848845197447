.menu-project-element-hovered {
    position: relative;
    isolation: isolate;
    font-family: 'NeueMontreal-Medium', sans-serif;
    text-decoration: none;
    /* font-weight:700; */
    z-index: 11;
    min-height: 40px;
    min-width: 470px;
    height: 50px;
    z-index: 100;
    mix-blend-mode: normal;
    color: #fb5803;
    // color: #fb0303;

    transition: transform 0.3s ease;
}

.menu-project-element-hovered-main {
    position: relative;
    isolation: isolate;
    font-family: 'NeueMontreal-Medium', sans-serif;
    text-decoration: none;
    /* font-weight:700; */
    z-index: 11;
    min-height: 40px;
    min-width: 470px;
    height: 50px;
    z-index: 100;
    mix-blend-mode: normal;
    // color: #fb5803;
    // color: #fb0303;

    transition: transform 0.3s ease;
}

.heavier {
    /* font-weight: 600; */
}

.menu-project-element {
    position: relative;
    font-family: 'NeueMontreal-Medium', sans-serif;
    text-decoration: none;
    z-index: 10;
    min-height: 40px;
    min-width: 470px;
    height: 50px;
    z-index: 1000;
    color: white;
    mix-blend-mode: difference;

    /* border:#fb5803 1px solid; */
}