.button {
    position: fixed;
    top: 0%;

    height: 100vh;
    padding: 0;
    border: none;
    background: none;

    z-index: 10;
    // cursor: url('../../../../icons/arrowLeftWhite.png') 9 9, auto;
    // mix-blend-mode: difference;

    outline: none;
}

.button.left {
    left: 0;
    right: 50%;
    cursor: url('../../../../icons/lessThan.png') 0 16, auto;
}

.button.right {
    right: 0;
    left: 50%;
    cursor: url('../../../../icons/moreThan.png') 9 9, auto;
}

.button:focus,
.button:active,
.button.previous:focus,
.button.previous:active {
    outline: none;
}

.button.next:focus,
.button.next:active {
    outline: none;
}