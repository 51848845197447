.project-text-up {

  // backdrop-filter: blur(1.5px);
  border-radius: 5px;

  display: none;
  position: fixed;
  top: 0;
  right: 0;
  margin-right: 35px;
  margin-top: 35px;

  max-width: 600px;

  font-family: 'NeueMontreal-Medium', sans-serif;
  z-index: 12;

  text-align: justify;
  font-size: 24px;
  letter-spacing: -1.1px;

  // text-align: right;
  // font-size: 25px;

  // border: #fb5803 1px solid;
}

.project-text-up-dif {
  mix-blend-mode: difference;
  color: white;
}

.project-text-up-about {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  margin-right: 35px;
  margin-top: 35px;

  max-width: 620px;

  font-family: 'NeueMontreal-Medium', sans-serif;
  z-index: 10;

  text-align: justify;
  font-size: 24px;
  letter-spacing: -1.1px;

  // text-align: right;
  // font-size: 25px;

  // border: #fb5803 1px solid;

}

.mobile-text-about {
  position: absolute;
  top: 0;
  left: 1rem;
  right: 1rem;
  min-width: 200px;

  // max-width: 90%;
  // margin-right: rem;
  // margin-left: 1rem;
  margin-top: 7rem;
  letter-spacing: -0.5px;
  font-family: 'NeueMontreal-Medium', sans-serif;
  font-size: 1.2rem;
  text-align: justify;

  // border: #fb5803 1px solid;

}

@media (min-width: 810px) {
  .project-text-up {
    display: block;
  }

  .project-text-up-about {
    display: block;
  }
}

// MOBILE FONT SIZE
@media screen and (min-device-width: 480px) and (orientation: portrait) {
  .mobile-about-text-font-size {
    font-size: 1.4rem;
  }
}

// TABLET FONT SIZE
@media screen and (min-device-width: 700px) and (orientation: portrait) {
  .mobile-about-text-font-size {
    font-size: 1.35rem;
  }
}