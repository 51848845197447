.centered-imageoverlay-project {
    position: fixed;
    min-height: 400px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    padding: 0;
    overflow: hidden;

    z-index: 11;

    // border: #fb5803 2px solid;
}

.centered-imageoverlay-project::-webkit-scrollbar {
    display: none;
    width: 0px;
    background: transparent;
}

.show {
    display: block;
}

.hide {
    display: none;
}