$base-url: '/public';

// MOBILE
.project-detail-mobile-main {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: white;
}

.first-cover-photo {
  position: relative;
  z-index: 0;
  height: auto;

  .first-cover-photo-img {
    position: relative;

    margin: 0 !important;
    z-index: 0;
    vertical-align: top;
    width: 100%;
    height: auto;
    margin: 0;
  }

  .project-title-mobile {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 0.5rem;
    margin-right: 1rem;

    font-family: 'NeueMontreal-Medium', sans-serif;
    font-size: 1.2rem;

    color: white;
    mix-blend-mode: difference;

  }
}

.texts-images-map-mobile {

  .div-map-mobile {

    height: auto;
    margin:0;
    outline: none;
    overflow: hidden;

    .text-map-mobile {
      padding: 1rem;
      padding-bottom: 2rem;

      font-family: 'NeueMontreal-Medium', sans-serif;
      font-size: 1.2rem;
      text-align: right;
      color: whitesmoke;
    }

    .img-map-mobile-container {

      .img-map-mobile {
        vertical-align: top;

        width: 100%;
        height: auto;
      }
    }

    .video-overlay-element {
      display: block;
      margin: 0;
      width: 100%;
      max-width: 100%;
      height: 100%;
      outline: none;

    }

    .video-overlay-mobile-div video {
      margin: 0;
      /* Add this line to remove default margin */
      max-width: 100%;
      height: auto;

      border: rgb(23, 255, 23) 2px solid;
    }
  }
}

.audio-mobile-container {
  opacity: 1;
  position: fixed;
  bottom: 0;
  right: 0;
  margin-bottom: 1rem;
  margin-right: 1.2rem;
  // width: 400px;
  height: auto;
  color: #333;

  // mix-blend-mode: difference;
  z-index: 1000;

  // border: red 1px solid;

  .circle-button {
    width: 35px;
    height: 35px;
    background-color: transparent;
    // background-color: white;
    // background-color: whitesmoke;
    mix-blend-mode: difference;
    border-radius: 50%;
    // border: 3px solid #fb5803; // #333;
    cursor: pointer;
  }

  .audio-mobile-element {
    // display: none;
  }
}

.audio-mobile-container audio::-webkit-media-controls-enclosure {
  opacity: 1 !important;
}

.audio-mobile-container audio::-webkit-media-controls-time-remaining-display {
  display: none;
}

.super {
  vertical-align: super;
  font-size: 0.5rem;
  line-height: normal;
}