.nameheader-mobile-main-container {
    position: absolute;

    align-items: left;
    justify-items: left;
    top: 0;
    left: 0;
    display: inline-block;

    width: 100%;
    height: 83px;
}

.nameheader-mobile-link-container {
    user-select: none;
    outline: none;
    position: fixed;
    left: 0;
    display: inline-block;
    text-decoration: none;
    text-align: left;
    margin: 0px;
    margin-left: 1rem;
    margin-top: 1rem;
    font-size: 2.5rem;
    font-family: 'NeueMontreal-Medium', sans-serif;
}


.iacopo-mobile {
    position: relative;
    user-select: none;
}

.guscetti-mobile {
    position: relative;
    user-select: none;
    margin-top: -12px;
}

.nameheader-mobile-main-container:hover {
    color: #fb5803;
}

.nameheader-mobile-link-container.clicked {
    color: #fb5803;
}

.mix-difference {
    mix-blend-mode: difference;
    color: white;
}

.nameheader-mobile-main-container .iacopoguscetti:active {
    color: black; 
}

@media (hover: hover) and (pointer: fine) {
    Link:hover { color: #fb5803; }
  }