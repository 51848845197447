.main-background-with-blur {
  /* position: absolute; */
  /* top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  z-index: 0;
  /* min-height: 100vh; */
  /* overflow: hidden; */
  /* height: auto; */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* border: blue 1px solid; */
  }

.vanta {
  /* min-height: 100vh; */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-width: 100vh;
  min-height: 100vh;
  /* width: 100%;
  height: 100%; */
  z-index: 0;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  }