.project-music-player-offset {
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;

    /* height: 100%; */
    margin-bottom: 35px;
    margin-right: 35px;

    z-index: 11;

    /* border: #fb5803 1px solid; */
}

.project-music-player {
    flex: 1;
}

.player-no-opacity {
    opacity: 0;
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-enclosure {
    opacity: 0.5;
}

audio::-webkit-media-controls-panel {
    background-color: white;
    opacity: 1;
    /* color: ; */
}

@media (max-height: 200px) {
    .project-music-player {
        display: none;
    }
}