.header-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;

    height: 40px;
    width: 100%;
    margin-bottom: -50px;

    /* border: #fb5803 1px solid; */
}

.menu-list-container {
    position: fixed;
    bottom: 0;
    left: 0;
    margin-left: 35px;
    margin-bottom: 30px;

    max-width: 450px;

    text-align: left;
    font-size: 35px;
    /* word-spacing: -1px; */

    /* border: #fb5803 1px solid; */
}

.menu-header-offsettop {}

.menu-header-element {
    font-family: 'NeueMontreal-Medium', sans-serif;
    z-index: 10;
    min-height: 40px;
    /* change here!!! */
    min-width: 470px;
    height: 50px;
    z-index: 10;
    mix-blend-mode: none;
    /* color: #333; */
    /* color: red; */

    /* border: #fb5803 1px solid; */
}

.menu-header-element:first-child {
    margin-top: 0;
}

.menu-header-element-hovered {
    isolation: isolate;
    font-family: 'NeueMontreal-Medium', sans-serif;
    z-index: 10;
    min-height: 40px;
    /* change here!!! */
    min-width: 470px;
    height: 50px;
    z-index: 10;
    mix-blend-mode: normal;
    color: #fb5803;

    transition: transform 0.3s ease;

    /* border: #fb5803 1px solid; */
}

.menu-header-element-nothovered {
    isolation: isolate;
    font-family: 'NeueMontreal-Medium', sans-serif;
    z-index: 10;
    min-height: 40px;
    /* change here!!! */
    min-width: 470px;
    height: 50px;
    z-index: 10;

    mix-blend-mode: difference;
    color: white;

    /* border: #fb5803 1px solid; */
}

.centered-image {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width:  100vw;
    max-width:  100vw;
    min-height: 100vh;
    height:     100vh;
    max-height: 100vh;

    overflow: hidden;

    filter: blur(0);
    transition: filter 0.5s ease-in-out;
    z-index: 0;
    object-fit: cover;

}

.image-no-blur {
    min-width:  105%;
    max-width:  105%;
    min-height: 105%;
    height:     105%;
    max-height: 105%;
    /* margin: 0px; */

    filter: blur(0);
    opacity: 1;

    /* visibility: hidden; */
    /* -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s; */

    margin: -10px -10px -10px -10px;
}

.image-blur {
    min-width:  105%;
    max-width:  105%;
    min-height: 105%;
    height:     105%;
    max-height: 105%;
    /* margin: 0px; */

    filter: blur(5px);

    margin: -10px -10px -10px -10px;
}

.appear {
    visibility: visible;
    animation: visible 0.15s forwards;

    margin: -10px -10px -10px -10px;
}

@keyframes visible {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}



.project-description-short {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    margin-right: 35px;
    margin-top: 35px;

    max-width: 450px;

    font-family: 'NeueMontreal-Medium', sans-serif;
    z-index: 10;

    text-align: right;
    /* word-spacing: -2px; */
    word-break: normal;
    font-size: 25px;

    mix-blend-mode: difference;
    color: white;

    /* border: #fb5803 1px solid; */
}

.project-description-short::after {
    content: "";
    display: inline-block;
    width: 0.5em;
}

@media (min-width: 655px) {
    .project-description-short {
        display: block;
    }
}