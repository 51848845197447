.lowerleft-mobile-main-container {
    position: fixed;
    bottom: 0;
    left: 0;
    margin-left: 1rem;
    margin-bottom: 1rem;
    user-select: none;
    outline: none;
}

.lowerleft-mobile-link-container {
    user-select: none;
    outline: none;
    text-decoration: none;
    text-align: left;
    margin: 0px;
    font-size: 2.5rem;
    font-family: 'NeueMontreal-Medium', sans-serif;
}

.llm-nohome {
    user-select: none;
    outline: none;
    color: white;
    mix-blend-mode: difference;
}

@media screen and (max-height: 150px) {
    .lowerleft-mobile-link-container {
        display: none;
        user-select: none;
        outline: none;
    }
}