.about-offset {
  width: 90px;
  position: fixed;
  bottom: 0;
  right: 0;


  margin-bottom: 35px;
  margin-right: 35px;
  // border:#fb5803 1px solid;
}

.about-lower-right {
  position: fixed;
  bottom: 0;
  right: 0;

  margin-bottom: 35px;
  margin-right: 35px;

  text-decoration: none;
  text-align: left;
  font-size: 35px;
  font-family: 'NeueMontreal-Medium', sans-serif;


  // border: #fb5803 1px solid;
}

.about-lower-right:hover {
  color: #fb5803;
}

.about-diff {
  color: white;
  mix-blend-mode: difference;
}

@media (max-height: 100px) {
  .about-lower-right {
    display: none;
  }
}

@media (max-width: 200px) {
  .about-lower-right {
    display: none;
  }
}

@media (max-width: 290px) and (max-height: 175px) {
  .about-lower-right {
    display: none;
  }
}

// MOBILE

.aboutmobile-lower-right-offset {
  position: fixed;
  bottom: 0;
  right: 0;
  margin:0;
  margin-bottom: 1rem;
  margin-right: 1rem;
  min-height: 40px;

  // border: #0703fb 3px solid;
}

.aboutmobile-lower-right {
  

  text-decoration: none;
  font-size: 2.5rem;
  font-family: 'NeueMontreal-Medium', sans-serif;

  // border: green 1px solid; 
}

.aboutmobile-lower-right:hover {
  color: #fb5803;
}

@media screen and (max-width: 270px) {
  .aboutmobile-lower-right {
    display: none;
  }
}

@media screen and (max-height: 460px) and (orientation: portrait) {
  .aboutmobile-lower-right {
    display: none;
  }
}