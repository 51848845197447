.global-container-about {
  // position: relative;
  z-index: 100;
  // height: 100vh;
  // height: 100svh;
  // overflow: hidden;
  overflow-y: hidden;

  .z-index-max-name {
    z-index: 100;
  }
  border: red 1px solid;

  .about-secondary-container {
    position: fixed;
    bottom: 0;
    left: 0;
    margin-left: 35px;
    margin-bottom: 30px;

    max-width: 250px;
    min-width: 250px;

    text-align: left;
    font-size: 35px;

    // border: #fb5803 1px solid;

  }

  .about-menu-offsettop {}

  .about-main-text {
    position: absolute;
    display: flex;
    flex-direction: column;
    flex: 1;
    font-family: 'NeueMontreal-Medium', sans-serif;

    // border: #fb5803 1px solid;

    .about-header {
      top: 0%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: clamp(20px, 2vw, 35px);
      overflow: hidden;

      // border: red 2px solid;

      .about-header-wrapper {
        display: flex;
        justify-content: center;
        overflow: hidden;
        height: 100%;
        width: 100%;

        // border: #fb5803 1px solid;

        .scrolling-text-header {
          display: flex;
          justify-content: center;
          text-align: center;
          align-items: center;
          white-space: nowrap;
          animation: scroll 13s infinite linear;

          // border: #fb5803 1px solid;
        }

        @keyframes scroll {
          100% {
            transform: translateX(-100%);
          }
        }

        .scrolling-text-header>span {
          display: inline-block;
        }
      }
    }

    .about-body {
      display: flex;

      // border: green 2px solid;

      .about-presentation-text {
        width: 50%;

        // border: red 1px solid;
      }

      .about-information {
        width: 50%;
        // border: blue 1px solid;

      }
    }
  }
}

@media screen and (max-width: 768px) {
  .desktop-container {
      display: none;
  }

  // body {
  //   overflow: hidden;
  // }
}

/* MOBILE */

.mobile-container {
  display: block;
}

@media screen and (min-width: 769px) {
  .mobile-container {
      display: none;
  }
}

/* IPAD MINI */
@media only screen 
and (min-device-width: 768px) /* Minimum device width for iPad Mini */
and (max-device-width: 1024px) /* Maximum device width for iPad Mini */
and (orientation: landscape) /* Landscape orientation */
and (-webkit-min-device-pixel-ratio: 1) {
  
  .desktop-container {
      display: none;
  }

  .mobile-container {
      display: block;
  }
}

/* KINDLE FIRE HDX */
@media only screen 
and (min-device-width: 800px) 
and (max-device-width: 1280px) 
and (orientation: landscape) {
  
  .desktop-container {
      display: none;
  }

  .mobile-container {
      display: block;
  }
}