.name-header-project-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;

    height: 40px;
    width: 100%;
    z-index: 10;

    margin-bottom: -50px;

    // border: #fb5803 1px solid;

    .name-project-offset{
    }

    .name-header-both-nothovered {
        position: fixed;
        top: 0;
        left: 0;
        margin-left: 35px;
        margin-top: 30px;
        z-index: 10;

        text-decoration: none;
        text-align: left;
        font-size: 35px;
        font-family: 'NeueMontreal-Medium', sans-serif;
        mix-blend-mode: difference;
        color: white;

        .iacopo {
            z-index: 10;
        }

        .guscetti {
            z-index: 10;
            margin-top: -12px;
        }
    }

    .name-header-both-hovered {
        position: fixed;
        top: 0;
        left: 0;
        margin-left: 35px;
        margin-top: 30px;
        z-index: 10;

        text-decoration: none;
        text-align: left;
        font-size: 35px;
        font-family: 'NeueMontreal-Medium', sans-serif;
        color: #fd620e;
        color: #fb5803;

        // border: #0ef30e 1px solid;

        .iacopo {
            z-index: 10;

            // border: #fb5803 1px solid;
        }

        .guscetti {
            z-index: 10;
            margin-top: -12px;
            // border: #fb5803 1px solid;
        }
    }

}