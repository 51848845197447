.mobile-vh-test {
  /* height: 100vh;
  height: 100dvh; */
  z-index: 1;
  min-height: 100vh;
  overscroll-behavior-y:none;
  /* cursor: url('/public/plus32.png') 16 16, auto; */
  /* overflow-y: hidden; */
}

.App {
  /* position: relative;
  text-align: center;
  overflow: hidden;*/

  min-height: 100vh;
  /* border: #ff1e00 4px solid; */
}

body {
  margin: 0px;
  /* padding: 20px 20px; */
}

.blue {
  color: #333;
}

.dark {
  color: #a1a1a1;
}

.custom-cursor-blue {
  cursor: url('/public/plus32.png') 16 16, auto;
}

.Link {
  user-select: none;
  -webkit-tap-highlight-color: transparent !important;
  cursor: url('/public/plus32orange.png') 16 16, auto;
}

.custom-cursor-dark {
  cursor: url('/public/plus32dark.png') 16 16, auto;
}

.header {
  position: relative;
  height: 60px;
}

.header a {
  position: absolute;
  bottom: 0;
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.header .home {
  left: 5%;
}

.header .projects {
  left: 45%;
}

.header .about {
  left: 90%;
}

.content-container {
  position: relative;
  z-index: 1;
}

.text-link {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}

.text-link.underline {
  text-decoration: underline;
}

.text-link:hover {
  text-decoration: underline;
  cursor: url('/public/plus32orange.png') 16 16, auto;
}

.sentence-spacing {
  margin-bottom: 10px;
}

.sentence-spacing-desktop {
  margin-bottom: 20px;
}

@font-face {
  font-family: 'NeueMontreal-Thin';
  src: url('/public/fonts/NeueMontreal/PPNeueMontreal-Thin.otf') format('opentype');
}

@font-face {
  font-family: 'NeueMontreal-Medium';
  src: url('/public/fonts/NeueMontreal/PPNeueMontreal-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'NeueMontreal-Bold';
  src: url('/public/fonts/NeueMontreal/PPNeueMontreal-Bold.otf') format('opentype');
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar {
  width: 8px;
  color: white;
}

::-webkit-scrollbar-track {
  background: transparent;
  color: white;
  background: white;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(165, 170, 173);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
